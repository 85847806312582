import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Typography,
} from "@mui/material";
import {
  collection,
  onSnapshot,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import React, { Fragment, useEffect, useState } from "react";
import { db } from "../../firebase";

const Products = () => {
  const [products, setProducts] = useState([]);
  const [data, setData] = useState([]);

  useEffect(() => {
    const colRef = collection(db, "products");
    const q = query(colRef, orderBy("name", "asc"));
    const unsubscribe = onSnapshot(q, (snapshot) => {
      let content = [];
      if (snapshot.docs) {
        snapshot.docs.forEach((doc) =>
          content.push({ id: doc.id, ...doc.data() })
        );
        setProducts(content);
        setData(content);
      }
    });

    // Unsubscribe from events when no longer in use
    return () => unsubscribe();
  }, []);
  return (
    <Grid container sx={{ p: 8 }} spacing={2}>
      <Grid sx={{ my: 2 }} item xs={12}>
        <Typography variant="h3">NOS PRODUITS</Typography>
      </Grid>
      {products &&
        products.map((product) => (
          <Fragment>
            <Grid item xs={12} md={3}>
              <Box>
                <Box>
                  <img src={product.image} height={200} />
                </Box>
                <Box sx={{ p: 3 }}>
                  <Typography variant="h6">
                    {product.name.toUpperCase()}{" "}
                  </Typography>
                  <Typography>
                    {product.details.slice(0, 100) + "..."}{" "}
                  </Typography>
                  <Typography sx={{ my: 2 }}>
                    <Button
                      component="a"
                      href={"/produits/" + product.id}
                      variant="contained"
                      sx={{ color: "white" }}
                    >
                      Voir le produit
                    </Button>
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Fragment>
        ))}
    </Grid>
  );
};

export default Products;
