import React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import NavItem from "./NavItem";
import { menuList } from "../menuList";
import { Drawer, Typography, useTheme } from "@mui/material";
import CallIcon from "@mui/icons-material/Call";

const SidebarNav = ({ onClose, open, variant }) => {
  const theme = useTheme();

  return (
    <Drawer
      anchor="left"
      onClose={() => onClose()}
      open={open}
      variant={variant}
      sx={{
        "& .MuiPaper-root": {
          width: "100%",
          maxWidth: 280,
        },
      }}
    >
      <Box
        sx={{
          height: "100%",
          padding: 1,
        }}
      >
        <Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
            }}
            width={1}
            paddingX={2}
            paddingY={1}
          >
            <Typography component="a" sx={{ mr: 0.5 }} href={"/"}>
              <img src="/images/logomax.png" alt="logo" width={150} />
            </Typography>
          </Box>
          <Box paddingX={2} paddingY={2}>
            {menuList.map((page) =>
              page.items ? (
                <Box sx={{ ml: 1, fontSize: 15 }}>
                  <NavItem title={page.name} items={page.items} />
                </Box>
              ) : (
                <Button
                  component="a"
                  href={page.href}
                  sx={{
                    display: "block",
                    color: "inherit",
                    fontSize: 15,
                    "&:hover": {
                      color: theme.palette.primary.main,
                    },
                  }}
                >
                  {page.name}
                </Button>
              )
            )}

            <Button
              component="a"
              target="_blank"
              startIcon={<CallIcon />}
              href="tel:  ++243822646363"
              variant="contained"
              sx={{
                mt: 1,
                display: { xs: "flex", md: "none" },
                color: "white",
              }}
            >
              +243 822 646 363
            </Button>
          </Box>
        </Box>
      </Box>
    </Drawer>
  );
};

SidebarNav.propTypes = {
  pages: PropTypes.object.isRequired,
};

export default SidebarNav;
