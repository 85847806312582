import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Typography,
  useTheme,
} from "@mui/material";
import React from "react";
import { footerData, services } from "../../data";

const Footer = () => {
  const theme = useTheme();

  return (
    <Grid container sx={{ p: 5, backgroundColor: "black" }} spacing={2}>
      <Grid item xs={12} md={4}>
        <Typography sx={{ display: { xs: "none", md: "flex" } }}>
          <img src={"/images/logomax.png"} alt="logo" width={"70%"} />
        </Typography>
        <Typography
          textAlign={"center"}
          sx={{ display: { xs: "flex", md: "none" } }}
        >
          <img src={"/images/logomax.png"} alt="logo" width="30%" />
        </Typography>
      </Grid>
      <Grid item xs={12} md={4}>
        <Typography sx={{ color: theme.palette.primary.main }} variant="h6">
          NAVIGATION
        </Typography>
        {services.map((item) => (
          <Button
            component="a"
            href={item.href}
            sx={{
              color: "white",
              fontSize: 17,
              display: "block",
              p: 0,
              "&:hover": {
                color: theme.palette.secondary.main,
              },
            }}
          >
            {item.title}
          </Button>
        ))}
      </Grid>
      <Grid item xs={12} md={4}>
        <Typography sx={{ color: theme.palette.primary.main }} variant="h6">
          RETROUVEZ-NOUS
        </Typography>
        <Typography color={"white"}>
          8184, Avenue de la Libération <br />
          Empire shopping center <br />
          1er Etage, Bureau 31 <br />
          Kinshasa - Gombe
        </Typography>
        <Box>
          {footerData.communnity.socials.map((social) => (
            <IconButton
              component="a"
              href={social.link}
              sx={{
                "&:hover": {
                  color: theme.palette.primary.main,
                  fontSize: 25,
                },
              }}
            >
              {social.icon}
            </IconButton>
          ))}
        </Box>
      </Grid>

      <Grid item xs={12}>
        <Divider />
        <Typography
          textAlign="center"
          color="white"
          sx={{
            fontSize: 13,
            my: 2,
          }}
        >
          Copyright &copy; {new Date().getFullYear()}, Maximum TBL Group. Tous
          droits réservés.
        </Typography>
      </Grid>
    </Grid>
  );
};

export default Footer;
