import About from "./pages/about";
import Products from "./pages/catalogue";
import ProductDetails from "./pages/catalogue/Details";
import Contact from "./pages/contact";
import Home from "./pages/home";

export default [
  {
    name: "Home",
    href: "/",
    element: <Home />,
  },
  {
    name: "About",
    href: "/a-propos",
    element: <About />,
  },
  {
    name: "Contact",
    href: "/contact",
    element: <Contact />,
  },
  {
    name: "Catalogue",
    href: "/produits",
    element: <Products />,
  },
  {
    name: "Produit details",
    href: "/produits/:productID",
    element: <ProductDetails />,
  },
];
