// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
//import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyDlTeywn6UE4bp93Jjh97q5di_nTw4f0C4",
  authDomain: "maximum-tbl-group.firebaseapp.com",
  projectId: "maximum-tbl-group",
  storageBucket: "maximum-tbl-group.appspot.com",
  messagingSenderId: "682804492643",
  appId: "1:682804492643:web:a245c95a21552351c57b56",
  measurementId: "G-FS817EVFVM",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
//export const auth = getAuth(app);
export const db = getFirestore(app);
