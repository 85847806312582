export const menuList = [
  {
    name: "Accueil",
    href: "/",
  },
  {
    name: "Nos produits",
    href: "/produits",
  },
  {
    name: "Contact",
    href: "/contact",
  },
  {
    name: "A propos de nous",
    href: "/a-propos",
  },
];
