import {
  collection,
  doc,
  getDoc,
  onSnapshot,
  query,
  where,
} from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { db } from "../../firebase";
import { Grid, Typography } from "@mui/material";

const ProductDetails = () => {
  const params = useParams();
  const [product, setProduct] = useState({
    name: "",
    image: "",
    details: "",
  });

  useEffect(() => {
    const unsubscribe = async () => {
      const docRef = doc(db, "products", params.productID);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) setProduct(docSnap.data());
    };

    // Unsubscribe from events when no longer in use
    return () => unsubscribe();
  }, []);

  return (
    <Grid container sx={{ p: 5 }} spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h4">{product.name.toUpperCase()} </Typography>
      </Grid>
      <Grid item xs={12} md={6}>
        <Typography>
          <img src={product.image} alt={product.name} width={400} />
        </Typography>
      </Grid>
      <Grid item xs={12} md={6}>
        <Typography>{product.details}</Typography>
      </Grid>
    </Grid>
  );
};

export default ProductDetails;
