import { Grid, Typography, useTheme } from "@mui/material";
import React from "react";
import Banner from "../../components/home/Banner";
import Products from "../../components/home/Products";

const Home = () => {
  const theme = useTheme();
  return (
    <main>
      <Banner />
      <Grid container sx={{ p: 8 }} spacing={2}>
        <Grid sx={{ p: 5, my: 5 }} item xs={12}>
          <Typography color={"primary"} textAlign={"center"} variant="h4">
            A PROPOS
          </Typography>
          <Typography textAlign={"center"}>
            Maximum TBL Group est une société qui évolue dans le domaine de la
            médecine thérapeutique encore appeler la thérapie magnétique: c est
            une méthode de traitement qui vous permet de vous faire traité a
            l'aide des bio aimants sans faire recour aux prise de comprimé aux
            injection, aux opération chirurgical etc.... Nous traitons les
            maladies telle que :rhumatisme,maladie liée au
            sang,paralysie,hémorroïde,diabète etc...
          </Typography>
        </Grid>
        <Grid item xs={12} sx={{ overflow: "hidden" }} md={6}>
          <Typography textAlign={"center"}>
            <img src="/images/therapy.JPG" height={400} alt="therapy" />
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="h4">
            Les bienfaits de la{" "}
            <span style={{ color: theme.palette.primary.main }}>
              {" "}
              MAGNETOTHERAPIE
            </span>
          </Typography>
          <Typography>
            En fonction de vos besoins, les aimants peuvent être utilisés sur
            l’ensemble du corps, pour une application locale ou encore via les
            points d’acupuncture. La magnétothérapie est une solution qui
            utilise les champs magnétiques pour aider à : Vitalité et Équilibre
            : stimuler la sécrétion de la molécule qui contribue à l’énergie
            cellulaire (ATP)* Soulagement et Mobilité : réduire la sensibilité à
            la douleur* et sécréter des molécules contribuant à la guérison
            (mélatonine et hormone de croissance)* Sommeil et Relaxation :
            réguler le moral, le comportement et les capacités cognitives* Les
            produits et solutions développés par AURIS vous permettent
            d’utiliser nos aimants de magnétothérapie sur n’importe quelle
            partie du corps. *Informations basées sur des études cliniques
            publiées
          </Typography>
        </Grid>
        <Grid item xs={12} sx={{ my: 8 }}>
          <Typography color={"primary"} variant="h4">
            NOS PRODUITS
          </Typography>
          <Products />
        </Grid>
      </Grid>
    </main>
  );
};

export default Home;
