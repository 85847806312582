import { alpha, Box, Button, Grid, Typography, useTheme } from "@mui/material";
import React from "react";

export default () => {
  const theme = useTheme();
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid
        container
        spacing={2}
        sx={{
          p: 8,
          pb: 10,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundImage: `linear-gradient(
        to bottom,
         ${alpha(theme.palette.primary.main, 0.3)},
         ${alpha("#000", 0.4)}
      ), url('/images/banner.jpg')`,
          height: 500,
        }}
      >
        <Grid
          container
          spacing={3}
          item
          sx={{ mt: 10, color: "white" }}
          xs={12}
          md={6}
        >
          <Grid item xs={12}>
            <Typography variant="h3" component="h1">
              MAXIMUM TBL GROUP
            </Typography>
            <Typography>
              Maximum TBL Group est une société qui évolue dans le domaine de la
              médecine thérapeutique encore appeler la thérapie magnétique
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};
