import { Box, Button, Grid, Typography, useMediaQuery } from "@mui/material";
import {
  collection,
  limit,
  onSnapshot,
  orderBy,
  query,
} from "firebase/firestore";
import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import { db } from "../../firebase";
import { useTheme } from "@emotion/react";

const Products = () => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  });

  var settings = {
    autoPlay: true,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 4,
    speed: 500,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          initialSlide: 4,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 4,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const [products, setProducts] = useState([]);

  useEffect(() => {
    const colRef = collection(db, "products");
    const q = query(colRef, orderBy("name", "asc"));
    const unsubscribe = onSnapshot(q, (snapshot) => {
      let content = [];
      if (snapshot.docs) {
        snapshot.docs.forEach((doc) =>
          content.push({ id: doc.id, ...doc.data() })
        );
        setProducts(content);
      }
    });

    // Unsubscribe from events when no longer in use
    return () => unsubscribe();
  }, []);
  return (
    <Slider {...settings}>
      {products &&
        products.map((product) => (
          <Box>
            <Box>
              <img src={product.image} height={200} />
            </Box>
            <Box sx={{ p: 3 }}>
              <Typography variant="h6">
                {product.name.toUpperCase()}{" "}
              </Typography>
              <Typography>{product.details.slice(0, 100) + "..."} </Typography>
              <Typography sx={{ my: 2 }}>
                <Button
                  component="a"
                  href={"/produits/" + product.id}
                  variant="contained"
                  sx={{ color: "white" }}
                >
                  Voir le produit
                </Button>
              </Typography>
            </Box>
          </Box>
        ))}
    </Slider>
  );
};

export default Products;
