import { Button, Alert, Grid, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
//import { send } from 'emailjs-com'

const Contact = () => {
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState(null);
  const [email, setEmail] = useState(null);
  const [message, setmessage] = useState(null);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [errorMesage, setErrorMessage] = useState("");

  const send = () => {};

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    send(
      "service_mzu47sy",
      "template_ztm794h",
      { name, email, message },
      "h7W9uZxQtwrcw0YBp"
    )
      .then((result) => {
        setLoading(false);
        setSuccess(true);
        setName("");
        setEmail("");
        setmessage("");
      })
      .catch((err) => {
        setLoading(false);
        setSuccess(false);
        setError(true);
        setErrorMessage(err.text);
      });
  };

  return (
    <main>
      <Grid container sx={{ p: 8 }}>
        <Grid item xs={12} md={6}>
          <Typography>
            <img src="/images/contact.png" width={400} />
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
        >
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              {success ? (
                <Alert variant="filled" severity="success">
                  Votre message a bien été envoyé! Merci de nous avoir contacté.
                </Alert>
              ) : null}
              <Grid item xs={12}>
                <Typography sx={{ my: 4 }} variant="h4">
                  Quelle est votre requête?
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  name="name"
                  variant="outlined"
                  label="Nom de l'entreprise"
                  onChange={(e) => setName(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  name="email"
                  variant="outlined"
                  label="Adresse email"
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  multiline
                  rows={5}
                  name="message"
                  variant="outlined"
                  label="Votre message"
                  onChange={(e) => setmessage(e.target.value)}
                />
              </Grid>
              <Grid item md={8}></Grid>
              <Grid item xs={12} md={4}>
                <Button
                  type="submit"
                  disabled={loading}
                  fullWidth
                  variant="contained"
                >
                  Envoyer
                </Button>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
    </main>
  );
};

export default Contact;
