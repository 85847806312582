import { Grid, Typography } from "@mui/material";
import React from "react";

const About = () => {
  return (
    <Grid container sx={{ p: 8 }} spacing={2}>
      <Grid item xs={12}>
        <Typography textAlign={"center"} variant="h4">
          A propos de nous
        </Typography>
        <Typography textAlign={"center"}>
          Maximum TBL Group est une société qui évolue dans le domaine de la
          médecine thérapeutique encore appeler la thérapie magnétique: c est
          une méthode de traitement qui vous permet de vous faire traité a
          l'aide des bio aimants sans faire recour aux prise de comprimé aux
          injection, aux opération chirurgical etc.... Nous traitons les
          maladies telle que :rhumatisme,maladie liée au
          sang,paralysie,hémorroïde,diabète etc...
        </Typography>
      </Grid>
    </Grid>
  );
};

export default About;
