import Layout from "./components/Layout";
import { Route, Routes } from "react-router-dom";
import routes from "./routes";

function App() {
  return (
    <Layout>
      <Routes>
        {routes.map((route) => (
          <Route exact path={route.href} element={route.element} />
        ))}
      </Routes>
    </Layout>
  );
}

export default App;
