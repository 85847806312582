import FacebookIcon from "@mui/icons-material/FacebookOutlined";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TwitterIcon from "@mui/icons-material/Twitter";
import theme from "../theme";

export const footerData = {
  entreprise: {
    title: "LV SPA",
    subtitles: [
      {
        title: "Tarifs",
        path: "/tarifs",
      },
      {
        title: "A propos de nous",
        path: "/a-propos",
      },

      {
        title: "Mentions Légales",
        path: "/mentions",
      },
    ],
  },

  prestations: {
    title: "Nos soins",
    subtitles: [
      {
        id: 1,
        title: "Mannicure",
        image: "/travaux.jpg",
        details:
          "Vos projets deconstruction méritent un partenaire fiable et efficace. Petits ou grands travaux de construction de bâtiments, de rénovation, de génie Civil, des routes, ou d’ouvrages d’art. Nous ne faisons aucun compromis sur la qualité. Consol360 est spécialisée dans la construction, l'analyse de défauts, la recherche de pannes,...",
      },
      {
        id: 2,
        title: "Epilation laser",
        image: "/travaux.jpg",
        details:
          "Vos projets deconstruction méritent un partenaire fiable et efficace. Petits ou grands travaux de construction de bâtiments, de rénovation, de génie Civil, des routes, ou d’ouvrages d’art. Nous ne faisons aucun compromis sur la qualité. Consol360 est spécialisée dans la construction, l'analyse de défauts, la recherche de pannes,...",
      },
      {
        id: 3,
        title: "Soins de visage",
        image: "/nettoyage.jpg",
        details:
          "Consol360 nettoie, entretien, désinsectise, dératise et s'occupe des immondices de vos bureaux, maison, grande surface,...",
      },
    ],
  },
  communnity: {
    title: "Contact",
    socials: [
      {
        name: "Facebook",
        link: "https://web.facebook.com/MaximumTBLGroupRDC/",
        icon: <FacebookIcon sx={{ color: theme.palette.primary.main }} />,
      },
      // {
      //   name: "Instagram",
      //   link: "https://instagram.com/congosolution360?igshid=ZDc4ODBmNjlmNQ==",
      //   icon: <InstagramIcon sx={{ color: theme.palette.primary.main }} />,
      // },
      // {
      //   name: "Linkedin",
      //   link: "https://linkedin.com/company/congosol360",
      //   icon: <LinkedInIcon sx={{ color: theme.palette.primary.main }} />,
      // },
      // {
      //   name: "Twitter",
      //   link: "https://twitter.com/consol360?t=_Gcxbfu1LBE__rbSSx1x2g&s=08",
      //   icon: <TwitterIcon sx={{ color: theme.palette.primary.main }} />,
      // },
    ],
  },
};

export const services = [
  {
    title: "Accueil",
    href: "/",
  },
  {
    title: "Catalogue",
    href: "/produits",
  },
  {
    title: "A propos",
    href: "/a-propos",
  },
];

export const products = [
  {
    name: "",
    image: "",
    details: "",
  },
];
